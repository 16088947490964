@import '../../../../scss/_variables.scss';

.footer {
  display: flex;
  min-height: 200px;
  background-color: $secondary-color;
  align-items: center;
  padding: 50px 0;

  &__logo {
    width: 50%;
    text-align: center;
    img {
      width: 50%;
    }
  }
  &__social {
    width: 50%;
    color: $primary-color;
    font-size: small;
    a {
      color: $primary-color;
      text-decoration: none;
    }
    &__logos {
      display: flex;
      width: 80%;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    display: flex;
    min-height: 200px;
    background-color: $secondary-color;
    width: 100%;
    align-items: center;
    align-self: center;
    position: relative;
    &__logo {
      width: 50%;
      border-right: 0px solid $primary-color;
      margin-right: 10px;
      padding-right: 10px;
      text-align: right;
      img {
        width: 20%;
      }

      color: $primary-color;
      text-decoration: none;
    }
    &__social {
      width: 40%;
      color: $primary-color;
      font-size: small;
      display: flex;
      flex-direction: column;

      a {
        color: $primary-color;
        text-decoration: none;
      }
      &__logos {
        display: flex;
        width: 80%;
        justify-content: flex-start;
        align-items: center;
        a {
          margin: 0 20px 0 20px;
        }
      }
    }
  }
}
