.privacy-container {
  display: block !important;
  width: 70%;
  margin-left: 15%;
}

@media screen and (max-width: 1023px) {
  .privacy-container {
    width: 95%;
    margin-left: 2.5%;
  }
}
