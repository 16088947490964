.edit-profile-form {
  width: 40%;
  margin-left: 30%;
  padding: 20px;
}

@media screen and (max-width: 1023px) {
  .edit-profile-form {
    width: 90%;
    margin-left: 5%;
    padding: 10px;
  }
}
