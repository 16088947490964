.claim-form-container,
.claim-delivered {
  margin-top: 50px;
  width: 50%;
  margin-left: 25%;
}

@media screen and (max-width: 1023px) {
  .claim-form-container,
  .claim-delivered {
    width: 90%;
    margin-left: 5%;
  }
}
