.landing-container {
  margin: 0vh !important;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background-color: #4e4637;
  &__items {
    margin: 1vh;
    border: 7px solid #dba800;
    border-radius: 15px;
    height: 100vh;
    display: flex;
    background-color: #4e4637;

    &__logo {
      justify-self: flex-start;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
      background-color: #4e4637;
      text-align: center;
      width: 7.5%;

      &__reverse {
        justify-self: flex-start;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        right: 0;
        bottom: 0;
        background-color: #4e4637;
        text-align: center;
        width: 7.5%;
      }
      img {
        width: 60%;
      }
    }

    &__header {
      position: absolute;
      bottom: 0;
      left: 0;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 3vh;
      &__title {
        text-align: left;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        h1 {
          margin: 0 !important;
          font-family: 'NouvelleVague' !important;
          text-transform: capitalize;
          font-size: 23vw;
          line-height: 23vw * 0.7;
          color: #dba800;
        }
        h2 {
          margin: 0 !important;
          font-family: 'NouvelleVague';
          text-transform: uppercase;
          font-size: 7vw;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }

    &__nav {
      margin: 5vh;
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      justify-content: space-evenly;
      align-self: flex-end;
      user-select: none;
      width: 50%;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;

      &__item {
        align-items: center;

        width: 33.3%;
        justify-content: space-evenly;
        display: flex;
        text-align: center;
        border: 0px #dba800 solid;
        border-radius: 0px;
        p {
          font-size: 1em;
          font-family: 'Lato', sans-serif;
          text-transform: uppercase;
          color: #dba800;
          font-weight: 500;
        }

        &__img {
          width: 100%;
          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

footer {
  background-color: #dba800;
}

@media screen and (max-width: 1023px) {
  .landing-container {
    margin: 0vh !important;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #4e4637;
    &__items {
      margin: 1vh;
      border: 2px solid #dba800;
      border-radius: 15px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: #4e4637;

      &__logo {
        font-family: 'Lato', sans-serif;
        display: flex;
        position: relative;
        color: #4e4637;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        background-color: transparent;

        img {
          width: 30%;
        }
      }

      &__header {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        &__title {
          text-align: center;
          user-select: none;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -o-user-select: none;
          h1 {
            margin: 0 !important;
            font-family: 'NouvelleVague' !important;
            text-transform: capitalize;
            text-align: center;
            font-size: 21vw;
            color: #dba800;
            line-height: 21vw * 0.8;
          }
          h2 {
            margin: 0 !important;
            font-family: 'NouvelleVague';
            text-transform: uppercase;
            font-size: 6.5vw;
            color: rgba(255, 255, 255, 0.9);
          }
        }
      }

      &__nav {
        margin: 0;
        min-height: 40vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        align-self: center;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        width: 90%;
        &__item {
          align-items: center;
          width: 80%;
          margin-top: 10px;
          justify-content: space-evenly;
          display: flex;
          text-align: center;
          height: 150px;
          border: 0px #dba800 solid;
          border-radius: 20px;
          height: max-content;
          p {
            font-size: 1.5em;
            font-family: 'Lato', sans-serif;
            text-transform: uppercase;
            color: #dba800;
            font-weight: 500;
          }

          &__img {
            :hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 730) {
  .landing-container {
    &__items {
      &__logo {
        img {
          width: 15%;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .landing-container {
    height: auto;
    min-height: 100vh;
    &__items {
      height: auto;
      margin: 2vh;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      &__logo {
        margin-top: 5vh;
        font-family: 'Lato', sans-serif;
        display: flex;
        color: #4e4637;
        width: 25%;
        background-color: transparent;
        justify-self: center;
      }

      &__header {
        &__title {
          user-select: none;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -o-user-select: none;
          h1 {
            margin: 0 !important;
            font-family: 'NouvelleVague' !important;
            text-transform: capitalize;
            font-size: 15vw;
            line-height: 15vw * 1;
            color: #dba800;
          }
          h2 {
            margin: 0 !important;
            font-family: 'NouvelleVague';
            text-transform: uppercase;
            font-size: 5vw;
            color: rgba(255, 255, 255, 0.753);
          }
        }
      }

      &__nav {
        margin-bottom: 5vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 0;
        &__item {
          align-items: center;
          min-width: 150px;
          width: 33.3%;
          justify-content: space-evenly;
          display: flex;
          text-align: center;
          border: 0px #dba800 solid;
          border-radius: 0px;
          p {
            font-size: 1em;
            font-family: 'Lato', sans-serif;
            text-transform: uppercase;
            color: #dba800;
            font-weight: 500;
          }

          &__img {
            width: 100%;
            :hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
