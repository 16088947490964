.landing-container__items__nav__item a {
  color: #dba800;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid #dba800;
  padding: 20px;
  width: 200px;
  border-radius: 20px;
  font-family: 'Lato', sans-serif;
  font-size: 1.5em;
  text-align: center;
}

.landing-container__items__nav__item a:hover {
  color: rgba(219, 168, 0, 0.644);
}
