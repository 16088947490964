.activation-container {
  height: 80vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.activation-image {
  margin-left: 35%;
  width: 30%;
}

@media screen and (max-width: 1023px) {
  .activation-image {
    margin-left: 10%;
    width: 80%;
  }
}
