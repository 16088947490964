.dashboard-container {
  width: 70%;
  margin-left: 15%;
}

.dashboard-menu-container {
  display: block;
  width: 100%;
  text-align: center;
}

.dashboard-menu-item {
  float: left;
  width: 48%;
  margin: 1%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #4e4637;
  color: #4e4637;
  text-transform: uppercase;
  border-radius: 10px;
}

.dashboard-menu-item:hover {
  border: 1px solid #dba800;
  color: #dba800;
}

@media screen and (max-width: 1023px) {
  .dashboard-container {
    width: 95%;
    margin-left: 2.5%;
  }
  .dashboard-menu-item {
    float: left;
    width: 48%;
    margin: 1%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid #dba800;
    color: #4e4637;
  }
}
