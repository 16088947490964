.reset-password-container {
  display: flex;
  flex-direction: column;

  width: 40%;
  margin-left: 25%;
}
.reset-password-container h3 {
  text-align: center;
}
.password-sent {
  padding: 20px;
}
.password-image {
  max-width: 100px;
  align-self: center;
}

@media screen and (max-width: 1023px) {
  .reset-password-container {
    margin-left: 5%;
    width: 90%;
  }
}
