.menu-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  font-family: 'Lato';
  align-self: center;
  margin-left: 5%;
  font-family: 'Lato', sans-serif;
  &__categories {
    background-color: #ffffffff;
    padding: 20px;
    border-radius: 15px;
    width: 20%;
    &__category {
      border-bottom: 1px rgba(153, 153, 153, 0.32) solid;
      cursor: pointer;
      padding: 5px;

      p {
        line-height: 1vh;
      }
      p:hover {
        color: green;
      }
    }
  }
  .quantity-label {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  &__products {
    width: 100%;
    padding: 20px;
    font-family: 'Lato', sans-serif;

    background-color: #ffffffff;
    display: block;
    &__card {
      cursor: pointer;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 200px;
      margin: 15px;
      height: 350px;
      border-radius: 20px;
      -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 219, 219, 0.747);
      -moz-box-shadow: 0px 0px 5px 2px rgba(219, 219, 219, 0.747);
      box-shadow: 0px 0px 5px 2px rgba(219, 219, 219, 0.747);
      transition: transform 0.05s linear;

      &__image {
        border-radius: 20px 20px 0 0;
        background-color: transparent;
        max-height: 100px;
        width: 100% !important;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      &__image:hover {
      }
      &__title {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 15px 0 15px;
      }
      &__description {
        padding: 0px 15px 0 15px;
      }
      &__button {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        &__quantity {
          margin-left: 5px;
          display: flex;
          flex-direction: row;
          &__text {
            margin: 0 15px 0 15px;
          }
        }
      }
    }
    &__card:hover {
      transform: scale(1.02);
    }
  }
}
.img-card {
  width: 100%;
}

.add-to-cart-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  &_button {
    margin: 15px 0 15px 0;
    Button {
      transition: 0.1s linear;
    }
  }
  &_quantity {
    margin: 15px 0 0 0;
  }
}

.product-modal {
  &-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 20px;
  }
  &-price {
    margin-top: 20px;
    font-size: large;
    font-weight: 700;
  }
}

.bottom-product-card-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.product-name {
  font-weight: 500;
}

.encargo-telefonico {
  width: 90%;
  margin-left: 5%;
  font-size: 11px;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .menu-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Lato';
    align-self: center;
    margin-left: 0;
    &__categories {
      background-color: #ffffffff;
      padding: 0;
      -webkit-box-shadow: 0px 6px 5px 0px rgba(219, 219, 219, 0.44);
      -moz-box-shadow: 0px 6px 5px 0px rgba(219, 219, 219, 0.44);
      box-shadow: 0px 6px 5px 0px rgba(219, 219, 219, 0.44);
      width: 100%;
      display: flex;
      overflow: scroll;
      border-radius: 0;
      white-space: nowrap;
      &__category {
        border-bottom: 0px rgba(153, 153, 153, 0.32) solid;
        cursor: pointer;
        min-width: 20%;
        width: fit-content;
        text-align: center;
        margin: 15px;
        white-space: nowrap; // Evita que el texto haga un salto de línea
        overflow: hidden; // Oculta el desbordamiento
        text-overflow: ellipsis; // Añade puntos suspensivos al final si es necesario
        p {
          margin: 0px;
          margin: 0px;
          white-space: nowrap; // Evita que el texto haga un salto de línea
          overflow: hidden; // Oculta el desbordamiento
          text-overflow: ellipsis; // Añade puntos suspensivos al final si es necesario
        }
      }
    }

    &__products {
      width: 100%;
      padding: 5px;
      background-color: #ffffffff;
      display: block;
      float: flex;
      flex-direction: column;
      &__card {
        float: left;
        width: 47.5%;
        margin: 1.25%;
        border-radius: 20px;
        height: 320px;
        -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 219, 219, 0.28);
        -moz-box-shadow: 0px 0px 5px 2px rgba(219, 219, 219, 0.28);
        box-shadow: 0px 0px 5px 2px rgba(219, 219, 219, 0.28);
        &__image {
          border-radius: 20px 20px 0 0;
          background-color: transparent;
          height: 90px;
          width: 100%;
        }
        &__title {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          padding: 15px 15px 0 15px;
        }
        &__description {
          padding: 5px 0 0 10px;
          font-style: italic;
        }
        &__button {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-around;
          padding: 10px;
          &__quantity {
            margin-left: 10px;
            display: flex;
            flex-direction: row;
            &__text {
              margin: 0 10px 0 10px;
            }
          }
        }
      }
      &__card:hover {
        transform: scale(1);
      }
    }
  }
}

#non-auth-view {
  height: auto;
  padding-bottom: 20px;
}
