.register-sign-in-button {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.phone-verification {
}

.number-input {
  max-width: 300px;
}
.PhoneInputCountry {
  display: flex;
  flex-direction: row;
}

.PhoneInputCountrySelect {
  margin-bottom: 5px;
  border-radius: 5px;
  max-width: 100px;
}

.PhoneInputInput {
  margin-bottom: 5px;
}

.phone-ver-container {
  width: 40%;
  margin-left: 30%;
  margin-top: 50px;
}

.verification-success {
}
@media screen and (max-width: 1023px) {
  .phone-ver-container {
    width: 90%;
    margin-left: 5%;
  }
}
