.profile-container {
  margin-left: 5%;
  margin-right: 5%;

  width: 90%;
}

.personal-info {
  margin-top: 50px;
  width: 30%;
}

.order-history {
  margin-top: 50px;
}

@media screen and (max-width: 1023px) {
  .personal-info {
    margin-top: 50px;
    width: 100%;
  }
}
