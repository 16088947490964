@import '../../../../scss/_variables.scss';
.form-time {
  margin: 20px 0 40px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.table-responsive-sm {
  margin: 20px 0 20px 0 !important;
}

.times-layout {
  display: block;
}

.times-parent-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.time-button {
  border: 1px solid $secondary-color;
  width: 50px;
  margin: 1%;
  color: $secondary-color;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  transition: 0.2s ease;
}
.time-button:hover {
  background-color: $secondary-color;
  color: $primary-color;
}

.empty-cart {
  height: 80vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.cart-image {
  margin-left: 35%;
  width: 30%;
}
.show-menu-button {
  width: 50%;
  margin-left: 25%;
}

.product-cart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &-information {
    p {
      padding: 5px;
      font-weight: lighter;
      color: rgb(118, 118, 118);
      font-size: small;
      align-self: flex-start;
    }
  }
}

@media screen and (max-width: 1023px) {
  .times-layout {
    width: 100%;
  }
  .form-time {
    display: flex;
    flex-direction: column;
  }
  .submit-button {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .cart-image {
    margin-left: 10%;
    width: 80%;
  }
}
