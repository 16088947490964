@import '../../../scss/_variables.scss';

.sign-in-container {
  background-color: $secondary-color;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  &__content {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
}

.login-form {
  background-color: white;
  top: 50;
  width: 30%;
  padding: 30px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 72px -22px rgba(231, 166, 26, 1);
  -moz-box-shadow: 0px 0px 72px -22px rgba(231, 166, 26, 1);
  box-shadow: 0px 0px 72px -22px rgba(231, 166, 26, 1);
}

.logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  img {
    width: 10%;
  }
}

@media screen and (max-width: 1023px) {
  .sign-in-container {
    height: 100vh;
    &__content {
      min-width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
    }
  }
  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px;
    img {
      width: 40%;
    }
  }
  .login-form {
    background-color: white;
    top: 50;
    width: 80%;
    padding: 20px;
    border-radius: 20px;
  }
}
