@import '../../../../scss/_variables.scss';

body {
  margin: 0 !important;
}

.make-order {
  height: 100vh;
  background-image: url(../../../../assets/img/svg/bg-pollo-yellow-small.svg);
  color: $secondary-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__frame {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &__text {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 50px;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  h2 {
    margin: 0 !important;

    font-family: 'NouvelleVague', sans-serif;
    font-size: 20vw;
    font-weight: bold;
    text-transform: lowercase;
  }
  h3 {
    margin: 0 !important;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
  }
}

.custom-button {
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  &__frame {
    border: 4px #4e4637 solid;
    border-radius: 20px;
    width: 120px;
    height: 120px;
    display: flex;

    background-color: $primary-color;
    justify-content: center;
    align-items: center;

    &__icon {
      img {
        height: auto;
      }
    }
  }

  p {
    margin: 0;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1023px) {
  .make-order {
    &__frame {
      flex-direction: row;
      &__text {
        width: 40%;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
      }

      &__buttons {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    h2 {
      margin: 0 !important;
      color: #4e4637;
      font-family: 'NouvelleVague', sans-serif;
      font-size: 9vw;
      font-weight: bold;
      text-transform: lowercase;
    }
    h3 {
      padding: 0px;
      margin: 0 !important;
      margin-top: 15px;
      font-family: 'Lato', sans-serif;
      font-size: 2vw;
      font-weight: 100;
    }
  }

  .custom-button {
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    width: 250px;
    height: 250px;
    &__frame {
      border: 4px #4e4637 solid;
      border-radius: 20px;
      width: 100%;
      height: 250px;
      display: flex;

      justify-content: center;
      align-items: center;

      &__icon {
        img {
          width: 100%;
        }
      }
    }

    p {
      margin: 0;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .make-order {
    &__frame {
      display: flex;
      flex-direction: row;
      &__text {
        width: 45%;
      }

      &__buttons {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    h2 {
      font-size: 10vw;
      font-weight: bold;
      text-transform: lowercase;
    }
    h3 {
      font-size: 2vw;
      font-weight: 100;
    }
  }

  .custom-button {
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    width: 100px;
    height: 100px;
    &__frame {
      border: 4px #4e4637 solid;
      border-radius: 20px;
      width: 100%;
      height: 100px;
      display: flex;

      justify-content: center;
      align-items: center;

      &__icon {
        img {
          width: 50px;
        }
      }
    }

    p {
      margin: 0;
      text-transform: uppercase;
    }
  }
}
