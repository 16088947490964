@import '../../../../scss/_variables.scss';

.contact {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  background-image: url(../../../../assets/img/svg/bg-pollo-yellow-small.svg);
  &__horarios {
    width: 100%;
    min-height: 50vh;
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    h2 {
      text-align: center;
      font-family: 'NouvelleVague';
      color: $secondary-color;
      font-size: 16vw;
      margin: 0;
    }
    &__forks {
      padding: 40px;
      position: relative;
      width: 87%;
      align-self: center;
      text-align: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 20px;

      img {
        width: 50px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        transform: rotate(343deg);
      }
    }

    &__box {
      background-color: $primary-color;
      padding: 40px;
      position: relative;
      width: 98%;
      align-self: center;
      text-align: center;
      align-content: center;
      border: 4px solid $secondary-color;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 20px;

      p {
        margin: 0;
      }
    }

    &__dia {
      font-family: $body-font-family;
      color: $secondary-color;
      margin: 10px 0 10px 0;
      &__dias {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: x-large;
      }
      &__horas {
        font-weight: lighter;
        font-size: large;
      }
    }
  }
  &__info {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    svg {
      width: 100px;
      margin: 0 10px 0 10px;
      #location-icon {
        animation: location-motion linear 0.8s infinite alternate;
      }
      #phone-icon {
        transform-origin: 50% 60%;
        animation: shake linear 0.8s infinite alternate;
        animation-delay: 200;
      }
    }
    &__box {
      font-family: 'NouvelleVague';
      color: $secondary-color;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      a {
        text-decoration: none;
        color: $secondary-color;
      }
      a:hover {
        color: #fafafa;
      }
      &__address {
        margin-bottom: 5vh;
        h3 {
          margin: 0;
          font-size: 11vw;
        }
        p {
          margin: 0;
          font-size: 11vw * 0.8;
        }
      }
      &__phone {
        margin: 0;
        padding: 0;
        font-size: 11vw * 0.9;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .contact {
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    background-image: url(../../../../assets/img/svg/bg-pollo-yellow-small.svg);
    &__horarios {
      width: 48%;
      height: auto;
      padding-top: 5vh;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      align-self: center;
      h2 {
        text-align: center;
        font-family: 'NouvelleVague';
        color: $secondary-color;
        font-size: 7vw;
        margin: 0;
      }
      &__forks {
        padding: 40px;
        position: relative;
        width: 80%;
        align-self: center;
        text-align: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px;

        img {
          transform: rotate(-30deg);
          width: 50px;
          position: absolute;
          top: -3px;
          left: 17px;
        }
      }

      &__box {
        background-color: transparent;
        padding: 40px;
        position: relative;
        width: 93%;
        align-self: center;
        text-align: center;
        align-content: center;
        justify-content: center;
        border: 4px solid $secondary-color;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px;
        min-height: 40vh;
        p {
          margin: 0;
        }
      }

      &__dia {
        font-family: $body-font-family;
        color: $secondary-color;
        margin: 15px 0 15px 0;
        &__dias {
          margin-bottom: 5px;
          font-weight: 500;
          font-size: xx-large;
        }
        &__horas {
          font-weight: lighter;
          font-size: x-large;
        }
      }
    }
    &__info {
      min-height: 50vh;
      justify-content: center;
      align-self: center;
      padding-right: 50px;
      svg {
        margin: 0 15px 0 30px;
        width: 150px;
        height: auto;
      }

      &__box {
        &__address {
          margin-bottom: 5vh;
          transform: rotate(0deg);
          h3 {
            margin: 0;
            font-size: 5vw;
          }
          p {
            margin: 0;
            font-size: 5vw * 0.8;
          }
        }
        &__phone {
          margin: 0;
          padding: 0;
          font-size: 5vw;

          a {
            text-decoration: none;
            color: $secondary-color;
            ::hover {
              color: #fafafa;
            }
          }
          a::hover {
            color: #fafafa;
          }
        }
      }
    }
  }
}

@keyframes location-motion {
  from {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-13%);
    transform: translateY(-13%);
  }
}

@keyframes shake {
  from {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  to {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
