@import '../../../scss/_variables.scss';

.menu-top {
  display: flex;

  button {
    &:hover {
      color: $primary-color;
    }
    &:focus,
    &:active {
    }
  }

  &__left {
    &-logo {
      font-size: x-large;
      padding-left: 15px;
      &-pathname {
        font-size: small;
      }
    }
  }

  &__right {
    position: absolute;
    right: 0;
  }
}
