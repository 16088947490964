$primary-color: #dba800;
$primary-color-hover: rgba(219, 168, 0, 0.644);

$secondary-color: #4e4637;
$secondary-color-hover: rgba(78, 70, 55, 0.616);

$title-font-family: 'NouvelleVague';
$body-font-family: 'Lato', sans-serif;
$body-font-color: $secondary-color;
$body-font-color-secondary: #fafafa;

$text-color-variant: #402e32;
