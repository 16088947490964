@import '../../../../scss/_variables.scss';

.button-show-menu {
  width: 100%;
  text-align: center;
  margin: 5px 0 10px 0;
}

.menu-list {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(../../../../assets/img/svg/bg-pollo-brown-small.svg);
  a {
    text-decoration: none;
  }
  &__motion-title {
    width: 100%;

    overflow: hidden;

    h2 {
      margin: 0;
      white-space: nowrap;
      color: $primary-color;
      font-family: $title-font-family;
      font-size: 19vw;
      text-transform: uppercase;
      animation: slide-left 8s alternate-reverse linear infinite;
    }
  }

  &__motion-title::-webkit-scrollbar {
    display: none;
  }

  &__categories {
    display: flex;
    flex-direction: column;

    &__category {
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin: 20px 0 20px 0;
      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 65%;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
        h3 {
          color: $text-color-variant;
          text-transform: uppercase;
          position: absolute;
          font-size: 6vw;
        }
      }
      &__products {
        margin-left: 1%;
        overflow: scroll;
        width: 98%;

        overflow-y: hidden;
        &__frame {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          padding-bottom: 20px;

          &__product {
            margin: 0 5px 5px;
            background-color: transparent;
            text-align: center;
            display: flex;
            flex-direction: column;
            min-width: 300px;
            max-width: 300px;
            justify-content: flex-start;
            &__image {
              width: 100%;
              height: 120px;
              border-radius: 10px 10px 0px 0;
              background-color: rgba(235, 229, 137, 0.384);
              overflow: hidden;
              align-self: center;
              margin-bottom: 5px;

              img {
                width: 100%;
                height: auto;
              }
            }

            &__name {
              font-family: 'NouvelleVague';
              margin: 0px 0 5px 0;
              text-align: left;
              font-family: $body-font-family;
              font-size: 5vw;

              font-weight: bold;
              text-transform: uppercase;
              color: $body-font-color-secondary;
            }

            &__description {
              padding: 5px;
              font-size: small;
              text-align: left;
              font-style: italic;
              color: #fafafad8;
            }
          }
        }
        /* width */
        &::-webkit-scrollbar {
          height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: rgba(64, 58, 46, 0.76);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $primary-color-hover;
          border-radius: 2px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }
}

@keyframes slide-left {
  from {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-70%);
    transform: translateX(-70%);
  }
}

@media screen and (min-width: 700px) {
  .menu-list {
    &__motion-title {
      h2 {
        font-size: 10vw;
        animation: none;
        text-align: center;
      }
    }

    &__motion-title::-webkit-scrollbar {
    }

    &__categories {
      &__category {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin: 20px 0 20px 0;
        &__title {
          display: flex;
          justify-content: center;
          align-items: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 20%;
          margin: 0 30px 0 30px;
          margin-bottom: 20px;
          img {
            width: 100%;
          }
          h3 {
            color: $text-color-variant;
            text-transform: uppercase;
            position: absolute;
            font-size: 2vw;
          }
        }
        &__products {
          margin-left: 1%;
          overflow: scroll;
          width: 98%;

          overflow-y: hidden;
          &__frame {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding-bottom: 20px;

            &__product {
              &__image {
              }

              &__name {
                font-family: 'NouvelleVague';
                margin: 0px 0 5px 0;
                text-align: left;
                font-family: $body-font-family;
                font-size: 1.5vw;
                font-weight: bold;
                text-transform: uppercase;
                color: $body-font-color-secondary;
              }

              &__description {
                padding: 5px;
                font-size: small;
                text-align: left;
                font-style: italic;
                color: #fafafad8;
                font-size: 1.2vw;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1023px) {
  .menu-list {
    &__motion-title {
      h2 {
        font-size: 10vw;
        animation: none;
        text-align: center;
      }
    }

    &__motion-title::-webkit-scrollbar {
    }

    &__categories {
      &__category {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin: 20px 0 20px 0;
        &__title {
          display: flex;
          justify-content: center;
          align-items: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 20%;
          margin: 0 30px 0 30px;
          margin-bottom: 20px;
          img {
            width: 100%;
          }
          h3 {
            color: $text-color-variant;
            text-transform: uppercase;
            position: absolute;
            font-size: 1.5vw;
          }
        }
        &__products {
          margin-left: 1%;
          overflow: scroll;
          width: 98%;

          overflow-y: hidden;
          &__frame {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding-bottom: 20px;

            &__product {
              margin: 0 5px 5px;
              background-color: transparent;
              text-align: center;
              display: flex;
              flex-direction: column;
              min-width: 400px;
              max-width: 400px;
              justify-content: flex-start;
              &__image {
                width: 100%;
                min-height: 150px;
                height: 150px;
                border-radius: 10px 10px 0px 0;
                background-color: rgba(235, 229, 137, 0.384);
                overflow: hidden;
                align-self: center;
                margin-bottom: 5px;
              }

              &__name {
                font-family: 'NouvelleVague';
                margin: 0px 0 5px 0;
                text-align: left;
                font-family: $body-font-family;
                font-size: 1.5vw;
                font-weight: bold;
                text-transform: uppercase;
                color: $body-font-color-secondary;
              }

              &__description {
                padding: 5px;
                font-size: small;
                text-align: left;
                font-style: italic;
                color: #fafafad8;
                font-size: 1vw;
              }
            }
          }
          /* width */
          &::-webkit-scrollbar {
            height: 5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: rgba(64, 58, 46, 0.76);
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $primary-color-hover;
            border-radius: 2px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
    }
  }
}
