.order {
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: scale(0.5);
  animation: inflate 0.3s forwards;
  width: '30%';
  border: 1px solid #bebebe;
  margin: '10px';
  padding: '10px';
  float: 'left';

  &:hover {
    cursor: pointer;
    background-color: lightgrey;
  }
}

@keyframes inflate {
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pop {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0.1);
  }
}

@keyframes background-color-interpolation {
  0% {
    background-color: white;
  }
  100% {
    background-color: green;
  }
}

.animated-bg {
  animation: background-color-interpolation 1s linear infinite alternate;
}

.order-pop {
  animation: pop 0.3s forwards;
}

@media screen and (max-width: 1023px) {
  .order {
    width: 45%;
  }
}
